/*
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-03-19 18:19:08
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-05-09 16:05:32
 * @FilePath: /mediatom-web/src/config/settingStyle.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// JS注入css变量 ===>>>> css变量转化成less变量
// 全局辅助样式配置
export const defaultStyleVar = {
  '--assisColor': '#64C8BC', // 辅助色
  '--mediumRadius': '10px', // 大圆角
  '--smallRadius': '4px', // 小圆角
  '--defaultFontSize': '14px', // 默认字体大小
  '--defaultFontColor': '#344563', // 默认字体颜色
  '--mediumMargin': '24px', // 模块间、页面侧边边距
  '--smallMargin': '24px', // 模块内，各个小模块边距
  '--fontSize': 14, // 用于首页切换em单位
  '--modelShadow': '0 1px 4px 0px rgba(21, 34, 50, 0.08)', // 模块阴影
  '--modelBorderColor': '#D9E1EC', // 模块边框色
  '--compMargin': '16px', // 控件间水平间距
  '--thPadding': '16px', // 表格表头padding
  // 公共组件
  '--compPadding': '18px', // 控件内边距(左侧)
  '--compDefaultHeight': '36px', // 控件默认高度
  '--compDisabledColor': '#858FA1', // 组件禁用文字颜色， 提示文案颜色
  '--compDisabledBc': '#FBFDFF', // 组件禁用背景色
  '--compBorderColor': '#d7dbec', // 组件边框色
  '--compValueColor': '#344563' // 组件选中值色
}
/**
 * 将配置的css样式变量挂在到指定id元素上
 * @export
 * @param {any} vars 变量配置
 * @param {any} id 元素ID
* */
export function defineStyleVar (vars, id) {
  const keys = Object.keys(vars)
  let doc
  if (!id) {
    doc = document.body
  } else {
    doc = document.getElementById(id)
  }
  keys.forEach((key) => {
    doc.style.setProperty(key, vars[key])
  })
}
