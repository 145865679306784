<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-03-12 18:19:06
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-04-02 14:42:20
 * @FilePath: /mediatom-web/src/layouts/BasicLayoutForManager.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <section class="basic-layout-container">
    <section>
      <div>
        <header class="gasket"></header>
        <header class="header"></header>
      </div>
      <main>
        <router-view />
      </main>
    </section>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'BasicLayoutForManager',
  data () {
    return {
    }
  },
  computed: {
    ...mapState({
      // 动态主路由
      mainMenu: (state) => state.permission.addRouters,
      indexIcon: (state) => state.autoweb.indexIcon
    })
  },
  created () {
  },
  methods: {
  }
}
</script>

<style lang="less">
.basic-layout-container{
  border: 1px solid;
  min-height: 100vh;
  position: relative;
  header{
    height: 68px;
    &.header{
      width: 100%;
      position: fixed;
      top: 0;
      border: 1px solid red;
      z-index: 10;
      background-color: #ccc;
    }
  }
}
</style>
