/*
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-03-12 18:33:35
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-03-19 15:14:06
 * @FilePath: /mediatom-web/src/core/ele.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
// 按需引入部分element组件，部分组件会出现全局变量命名冲突，谨慎引入
import {
  Cascader,
  Image,
  DatePicker as ElDatePicker,
  Table,
  TableColumn,
  Popover,
  Checkbox,
  CheckboxGroup,
  Tooltip,
  Button,
  Empty,
  Input,
  Radio,
  RadioGroup,
  InputNumber,
  Loading,
  Select,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Option,
  Dialog,
  Form,
  FormItem
} from 'element-ui'

// ele
Vue.use(Cascader)
Vue.use(Image)
Vue.use(ElDatePicker)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Popover)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Tooltip)
Vue.use(Button)
Vue.use(Empty)
Vue.use(Input)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(InputNumber)
Vue.use(Select)
Vue.use(Option)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)

Vue.use(Loading.directive)
Vue.use(Dialog)
Vue.use(Form)
Vue.use(FormItem)
