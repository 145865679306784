import storage from 'store'
import expirePlugin from 'store/plugins/expire'
import notification from 'ant-design-vue/es/notification'
import { login, getInfo, logout } from '@/api/login'
import { resetApiKey } from '@/api/system'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import router from '@/router'
import store from '@/store'
import { dashboardLayoutSetting, updateMockDataSts } from '@/api/dashboard'
// 添加过期插件到存储库
storage.addPlugin(expirePlugin)
let hasNotification = false
const user = {
  // 用户状态
  state: {
    id: undefined,
    // 用户令牌
    token: '',
    // 用户名
    name: '',
    // 用户头像
    avatar: '',
    // 未读消息
    unReadMessage: '',
    // 素材审核是否显示广告平台权限
    showPlat: '',
    auditType: '',
    // 用户角色
    roles: [],
    // 角色ID
    roleId: '',
    // 用户信息
    info: {},
    // 审计
    audit: 0,
    // 跳过
    skip: undefined,
    // 媒体访问
    mediaAccess: undefined,
    // 管理员用户ID
    adminUserId: undefined,
    // 是否是管理员
    isAdmin: 1,
    // 系统用户仪表板设置
    sysUserDashboardSetting: {},
    // 是否是模拟
    isMock: '0',
    // 是否是第一次登录
    isFirstLogin: undefined,
    // API密钥
    apiKey: undefined,
    // API秘密
    apiSecret: undefined,
    // 素材权限
    creative: undefined,
    // 校验权限
    checkable: undefined,
    // 合同签署
    contractSts: undefined,
    // 素材审核是否开启 A 开启  S 关闭
    creativeAuditSts: undefined,
    // 是否有未读工单
    workOrderReadSts: 0,
    // 自定义规则是否允许上传文件
    upfile: undefined,
    // adx账户是否是海外账户 0 否 1 是
    cur: undefined
  },
  mutations: {
    SET_ID: (state, id) => {
      state.id = id
    },
    // 设置令牌
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    // 设置名字
    SET_NAME: (state, { name }) => {
      state.name = name
    },
    // 设置头像
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    // 设置未读消息
    SET_UNREADMESSAGE: (state, unReadMessage) => {
      state.unReadMessage = unReadMessage
    },
    SET_SHOWPLAT: (state, showPlat) => {
      state.showPlat = showPlat
    },
    SET_AUDITTYPE: (state, auditType) => {
      state.auditType = auditType
    },
    // 设置角色
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    // 设置角色ID
    SET_ROLEID: (state, roleId) => {
      state.roleId = roleId
    },
    // 设置信息
    SET_INFO: (state, info) => {
      state.info = info
    },
    // 设置审计
    SET_AUDIT: (state, audit) => {
      state.audit = audit
    },
    // 设置跳过
    SET_SKIP: (state, skip) => {
      state.skip = skip
    },
    // 设置媒体访问
    SET_MEDIAACCESS: (state, mediaAccess) => {
      state.mediaAccess = mediaAccess
    },
    // 设置管理员用户ID
    SET_ADMINUSERID: (state, adminUserId) => {
      state.adminUserId = adminUserId
    },
    // 设置是否是管理员
    SET_ISADMIN: (state, isAdmin) => {
      state.isAdmin = isAdmin
    },
    // 设置系统用户仪表板设置
    SET_SYSUSERDASHBOARDSETTING: (state, sysUserDashboardSetting) => {
      state.sysUserDashboardSetting = sysUserDashboardSetting
    },
    // 设置是否是模拟
    SET_ISMOCK: (state, isMock) => {
      state.isMock = isMock
    },
    // 设置是否是第一次登录
    SET_ISFIRSTLOGIN: (state, isFirstLogin) => {
      state.isFirstLogin = isFirstLogin
    },
    // 设置API密钥
    SET_APIKEY: (state, apiKey) => {
      state.apiKey = apiKey
    },
    // 设置API秘密
    SET_APISECRET: (state, apiSecret) => {
      state.apiSecret = apiSecret
    },
    SET_CREATIVE: (state, creative) => {
      state.creative = creative
    },
    SET_CHECKABLE: (state, checkable) => {
      state.checkable = checkable
    },
    // 合同签署
    SET_CONTRACTSTS: (state, contractSts) => {
      state.contractSts = contractSts
    },
    SET_CREATIVEAUDITSTS: (state, sts) => {
      state.creativeAuditSts = sts
    },
    SET_WORKORDERREADSTS: (state, sts) => {
      state.workOrderReadSts = sts
    },
    SET_UPFILE: (state, upfile) => {
      state.upfile = upfile
    },
    SET_CUR: (state, cur) => {
      state.cur = cur
    }
  },
  actions: {
    // 登录
    Login ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo)
          .then((response) => {
            if (response.code === 10002 || response.code === 10003) {
              notification.error({
                message: '登陆失败',
                description: response.msg,
                duration: 2
              })
              return resolve(response)
            }
            hasNotification = false
            const result = response.data
            storage.set(ACCESS_TOKEN, result, new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
            commit('SET_TOKEN', result)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 获取用户信息
    GetInfo ({ commit }) { // 获取用户信息
      return new Promise((resolve, reject) => {
        getInfo()
          .then((response) => {
            const result = response.data // 定义结果为响应的数据
            if (result && result.roles && result.roles.length > 0) {
              const roles = result.roles // 定义角色为结果的角色
              commit('SET_ID', result.id) // 提交设置ID
              commit('SET_ROLES', roles) // 提交设置角色
              commit('SET_ROLEID', result.roleId) // 提交设置角色ID
              commit('SET_INFO', result) // 提交设置信息
              commit('SET_AUDIT', result.audit) // 提交设置审计
              commit('SET_MEDIAACCESS', result.mediaAccess) // 提交设置媒体访问
              commit('SET_ADMINUSERID', result.adminUserId) // 提交设置管理员用户ID
              commit('SET_ISADMIN', result.isAdmin) // 提交设置是否为管理员
              commit('SET_SKIP', result.skip) // 提交设置跳过
              commit('SET_UNREADMESSAGE', result.unReadMessage) // 提交设置未读消息
              commit('SET_SYSUSERDASHBOARDSETTING', result.sysUserDashboardSetting) // 提交设置系统用户仪表板设置
              commit('SET_ISMOCK', result.isMock) // 提交设置是否模拟
              commit('SET_ISFIRSTLOGIN', result.isFirstLogin) // 提交设置是否首次登录
              commit('SET_APIKEY', result.apiKey) // 提交设置API密钥
              commit('SET_APISECRET', result.apiSecret) // 提交设置API密钥
              commit('SET_CREATIVE', result.creative) // 素材权限
              commit('SET_CHECKABLE', result.checkable) // 校验权限
              commit('SET_SHOWPLAT', result.showPlat) // 素材审核是否显示广告平台权限
              commit('SET_AUDITTYPE', result.auditType)
              commit('SET_CONTRACTSTS', result.contractSts)
              commit('SET_WORKORDERREADSTS', result.workOrderReadSts)
              commit('SET_UPFILE', result.upfile)
              commit('SET_CUR', result.cur)
              if (+result.workOrderReadSts === 1 && !hasNotification) {
                hasNotification = true
                const key = `open${Date.now()}`
                notification.error({
                  message: '系统提醒',
                  description: (h) => {
                    return (<div>你有未处理的<span style = { { color: 'blue', cursor: 'pointer' } } onClick={() => {
                      notification.close(key)
                      router.push({ path: '/accountInfo/workOrder' })
                    } }>工单</span>，请及时查看！</div>)
                  },
                  duration: null,
                  icon: (h) => {
                    return h(
                      'a-icon',
                      {
                        props: {
                          type: 'message'
                        },
                        style: {
                          color: 'red'
                        }
                      }
                    )
                  },
                  // btn: (h) => {
                  //   return h(
                  //     'a-button',
                  //     {
                  //       props: {
                  //         type: 'link',
                  //         size: 'small'
                  //       },
                  //       on: {
                  //         click: () => notification.close(key)
                  //       }
                  //     },
                  //     '知道了'
                  //   )
                  // },
                  key,
                  onClose: () => notification.close(key)
                })
              }
              store.dispatch('asyncPermission/SetPermission', result.permissionMap)
            } else {
              reject(response)
            }
            commit('SET_NAME', {
              name: result.loginId
            })
            commit('SET_AVATAR', 'https://gw.alipayobjects.com/zos/rmsportal/WdGqmHpayyMjiEhcKoVE.png') // 提交设置头像
            resolve(response)
          })
          .catch((error) => {
            commit('SET_TOKEN', '') // 提交设置令牌为空
            commit('SET_ROLES', []) // 提交设置角色为空数组
            storage.remove(ACCESS_TOKEN) // 移除存储的访问令牌
            store.commit('SET_ROUTERS', []) // 提交设置路由器为空数组
            router.resetRouter() // 重置路由器
            reject(error)
          })
      })
    },
    resetToken ({ commit }) { // 重置令牌
      return new Promise((resolve) => {
        commit('SET_TOKEN', '') // 提交设置令牌为空
        commit('SET_ROLES', []) // 提交设置角色为空数组
        storage.remove(ACCESS_TOKEN) // 移除存储的访问令牌
        resolve()
      })
    },
    // 登出
    Logout ({ commit, state }) { // 登出
      return new Promise((resolve) => {
        logout() // 登出
          .then((res) => {
            commit('SET_TOKEN', '') // 提交设置令牌为空
            commit('SET_ROLES', []) // 提交设置角色为空数组
            storage.remove(ACCESS_TOKEN) // 移除存储的访问令牌
            store.commit('SET_ROUTERS', []) // 提交设置路由器为空数组
            router.resetRouter() // 重置路由器
            resolve(res)
          })
          .catch((err) => {
            console.log('logout fail:', err)
            resolve()
          })
          .finally((res) => {
            resolve(res)
          })
      })
    },
     // 收页面版布局设置
    changeSysUserDashboardSetting ({ commit }, sysUserDashboardSetting) {
      commit('SET_SYSUSERDASHBOARDSETTING', sysUserDashboardSetting)
      dashboardLayoutSetting(sysUserDashboardSetting)
    },
    // 首页是否模拟数据
    changeIsMock ({ commit }, isMock) {
      commit('SET_ISMOCK', isMock)
      updateMockDataSts({ isMock })
    },
    /**
     * @param {*} param0
     * @param {*} type
     */
    async changeKey ({ commit }, type) {
      await resetApiKey({ type: '' + type })
      // 1 重置api_secret // 1重置API密钥
      // 0 重置api_key // 0重置API密钥
      this.dispatch('GetInfo')
    },
    changeIsFirstLogin ({ commit }, isFirstLogin) { // 改变是否首次登录
      commit('SET_ISFIRSTLOGIN', isFirstLogin) // 提交设置是否首次登录
    },
    changeCreativeAuditSts ({ commit }, creativeAuditSts) {
      commit('SET_CREATIVEAUDITSTS', creativeAuditSts)
    },
    // 修改工单状态
    changeWorkOrderReadSts ({ commit }, workOrderReadSts) {
      commit('SET_WORKORDERREADSTS', workOrderReadSts)
    }

  }
}

export default user
