/*
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2023-12-05 15:42:17
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-07-16 15:19:37
 * @FilePath: /mediatom-web/src/store/modules/autoweb.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
const isMedPro = process.env.NODE_ENV === 'development' ? true : window.location.host !== 'manager.ads.cctv.com'
const mDomain = process.env.VUE_APP_ISMANAGER === 'false' ? 'https://app.mediatom.cn' : 'https://xch.mergex.cn:7001'
const state = {
  isMedPro: isMedPro,
  // 广告位、应用静态资源基路径
  resourceUrl: isMedPro ? 'http://creative.medproad.com/material/' : 'https://res.ads.cctv.com/material/',
  // 当前页面ip
  origin: process.env.NODE_ENV === 'development' ? mDomain : window.location.origin,
  // 直客静态资源基路径
  adplanResourceUrl: isMedPro ? 'http://creative.medproad.com' : 'https://res.ads.cctv.com',
  // 平台名
  title: '',
  // 是否允许注册
  allowRegister: false,
  // 注册Icon地址
  registerIcon: '',
  // 登录Icon地址
  loginIcon: '',
  // 主页icon地址
  indexIcon: '',
  // 标签页logo地址
  headerIcon: '',
  // 服务条款地址
  serviceAgreement: '',
  // 隐私协议地址
  privacyAgreement: '',
  // 登陆背景图
  loginImage: '',
  // 是否可以修改密码
  updatePassword: false
}
const mutations = {
  SET_TITLE: (state, title) => {
    state.title = title
  },
  SET_ALLOWREGISTER: (state, flag) => {
    state.allowRegister = +flag === 1
  },
  SET_UPDATEPASSWORD: (state, flag) => {
    state.updatePassword = +flag === 1
  },
  SET_REGISTERICON: (state, url) => {
    state.registerIcon = url
  },
  SET_LOGINICON: (state, url) => {
    state.loginIcon = url
  },
  SET_INDEXICON: (state, url) => {
    state.indexIcon = url
  },
  SET_HEADERICON: (state, url) => {
    state.headerIcon = url
  },
  SET_SERVICEAGrEEMENT: (state, url) => {
    state.serviceAgreement = url
  },
  SET_PRIVACYAGREEMENT: (state, url) => {
    state.privacyAgreement = url
  },
  SET_LOGINIMAGE: (state, url) => {
    state.loginImage = url
  }
}

const actions = {
  SetWebSetting ({ commit }, data = {}) {
    commit('SET_TITLE', data.headerName)
    commit('SET_ALLOWREGISTER', data.allowRegister)
    commit('SET_UPDATEPASSWORD', data.updatePassword)
    commit('SET_REGISTERICON', data.registerIcon)
    commit('SET_LOGINICON', data.loginIcon)
    commit('SET_INDEXICON', data.indexIcon)
    commit('SET_HEADERICON', data.headerIcon)
    commit('SET_SERVICEAGrEEMENT', data.serviceAgreement)
    commit('SET_PRIVACYAGREEMENT', data.privacyAgreement)
    commit('SET_LOGINIMAGE', data.loginImage)
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
